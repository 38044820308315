<template>
  <div :class="$style.payment">
    <div v-if="$store.state.user.restriction_base.toLowerCase() == 'business'" :class="$style.warehouseBox" class="mb-3">
      <a-select
        placeholder="Pilih Warehouse"
        show-search
        option-filter-prop="label"
        @change="handleWarehouse"
      >
        <a-select-option
          v-for="(item, index) in warehouseData"
          :key="index"
          :value="item.seller_id"
          :label="item.name"
        >
          <div>
            {{ item.name }}
          </div>
        </a-select-option>
      </a-select>
    </div>

    <a-row :gutter="24" class="mb-3">
      <a-col :span="8">
        <a-input-search
          :default-value="$route.query.invoice_number"
          :placeholder="$t('utils.search')"
          :loading="loading"
          :disabled="loading"
          allow-clear
          @change="onSearch"
        />
      </a-col>
      <a-col :span="5" :offset="11">
        <a-select
          :default-value="$route.query.statuses ?? ''"
          class="w-100 select-antd-default filter"
          :placeholder="$t('finance.payment_status')"
          :options="paymentFilterState"
          @change="onSearchFilter"
        />
      </a-col>
    </a-row>

    <a-row class="d-flex align-items-center justify-content-end">
      <a-button
        :class="$style.buttonConfirm"
        :disabled="!dataPayments?.length"
        @click.prevent="onShowModalExport"
      >
        {{ $t('warehouse.export_data') }}
      </a-button>
    </a-row>

    <!-- LIST DATA PAYMENT  -->
    <div :class="$style.list" class="my-4 p-0">
      <div :class="$style.headerList" class="d-flex">
        <a-col :span="8" class="d-flex justify-content-center text-center">
          No. Invoice
        </a-col>
        <a-col :span="6" class="d-flex justify-content-center text-center">
          Tanggal Pemesanan
        </a-col>
        <a-col :span="6" class="d-flex justify-content-center text-center">
          Total Tagihan
        </a-col>
        <!-- <a-col :span="8" class="d-flex justify-content-center">
            Batas Waktu Pembayaran
          </a-col> -->
        <a-col :span="4" class="d-flex justify-content-center text-center">
          Status Pembayaran
        </a-col>
      </div>
      <a-skeleton :loading="isLoading" :paragraph="{ rows: 5 }" active>
        <a-collapse>
          <a-collapse-panel
            v-for="(item) in dataPayments"
            :key="item.order_id"
            :class="$style.dataList"
            :show-arrow="false"
          >
            <template slot="header">
              <div class="d-flex align-items-center">
                <a-col :span="8" class="d-flex justify-content-center">
                  {{ item.invoice_number }}
                </a-col>
                <a-col :span="6" class="d-flex justify-content-center">
                  {{ $moment(item.order_date).format('DD MMM YYYY, HH:mm') }}
                </a-col>
                <a-col :span="6" class="d-flex justify-content-center">
                  {{ `Rp. ${parseNum(item.invoice_items[0].sub_total)}` }}
                </a-col>
                <!-- <a-col :span="8" class="d-flex justify-content-center">
                    {{ $moment(item?.expired_date).format('DD MMM YYYY HH:mm') }}
                  </a-col> -->
                <a-col :span="4" class="d-flex justify-content-center">
                  <div>
                    {{ statusPayment(item.payment.status) }}
                  </div>
                </a-col>
              </div>
            </template>

            <div class="d-flex">
              <a-col :span="12" class="d-flex flex-column">
                <a-col :span="24" class="d-flex px-2">
                  <a-col :span="10">
                    No order
                  </a-col>
                  <a-col :span="14">
                    {{ item.order_number }}
                  </a-col>
                </a-col>

                <a-col :span="24" class="d-flex px-2">
                  <a-col :span="10">
                    Nama Customer
                  </a-col>
                  <a-col :span="14">
                    {{ item.full_name }}
                  </a-col>
                </a-col>

                <a-col :span="24" class="d-flex px-2">
                  <a-col :span="10">
                    Batas Waktu Pembayaran
                  </a-col>
                  <a-col :span="14">
                    {{ item?.expired_at ? $moment(item.expired_at).format('DD MMMM YYYY - HH:mm') : '-' }}
                  </a-col>
                </a-col>
              </a-col>

              <a-col :span="12" class="d-flex justify-content-end align-items-center">
                <a-icon 
                  type="eye"
                  class="mr-3"
                  style="color: #E00; cursor: pointer;"
                  @click="detailInvoice(item.order_id)"
                />
                <a-button
                  v-if="item.payment_state === 'Belum Lunas'" 
                  :class="$style.buttonConfirm"
                  @click="handleSendInvoice"
                >
                  Kirim
                </a-button>
              </a-col>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </a-skeleton>
    </div>

    <!-- REVISI PAYMENT -->
    <ModalSendInvoice 
      v-if="modalSendInvoice"
      @handleSendInvoice="handleSendInvoice"
    />

    <Pagination
      class="text-right"
      :total="pagination.total_items"
      :page-size="pagination.size"
      :total-length-per-page="dataPayments?.length"
      :default-page="page"
      @changePage="changePage"
    />

    <ModalExportExcel
      :visible="showModalExportExcel"
      :on-close="onCloseModalExportExcel"
      :is-loading="loading"
      @handleSubmitModal="exportDataTable"
    />
  </div>
</template>

<script>
import invoice from '../composables/invoice'
import ModalSendInvoice from './ModalSendInvoice.vue'
import Pagination from '@/components/Pagination/index.vue'
import XLSX from 'xlsx';
import ModalExportExcel from '@/views/finance/invoice-channel/partials/ModalExportExcel'
import { getInvoiceByOrder } from '@/api/channels/distributor'
import { getInvoiceList } from '@/api/invoice'
import { useCurrency } from '@/composable/useCurrency'

const { format } = useCurrency()

export default {
  name: 'ListInvoice',
  components: {
    ModalSendInvoice,
    Pagination,
    ModalExportExcel,
  },
  props: {
    selectedMenu: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      page,
      pagination,
      changePage,
      paymentFilterState,
      dataPayment,
      handleSendInvoice,
      modalSendInvoice,
      warehouseData,
      getInvoice,
      handleWarehouse,
      detailInvoice,
      loading,
      sellerId,
      businessId,
      selectedWarehouse,
      isLoading,
    } = invoice()

    return {
      page,
      pagination,
      changePage,
      paymentFilterState,
      dataPayment,
      handleSendInvoice,
      modalSendInvoice,
      warehouseData,
      getInvoice,
      handleWarehouse,
      detailInvoice,
      loading,
      sellerId,
      businessId,
      selectedWarehouse,
      isLoading,
    }
  },
  data() {
    return {
      detailDateInvoice: null,
      dataPayments: [],
      showModalExportExcel: false,
    }
  },
  watch: {
    dataPayment(value) {
      this.fetchInvoice(value)
    },
  },
  methods: {
    async fetchInvoice(orders) {
      this.dataPayments = orders?.length
        ? await Promise.all(orders.map(async (dataPayment) => {
            const { data: { data: response } } = await getInvoiceByOrder(dataPayment.order_id)
            dataPayment.expired_at = response?.expired_at
            return dataPayment
          }))
        : []
    },
    parseNum(val) {
      return val.toLocaleString(this.$store.state?.settings?.locale || 'id-ID')
    },
    statusPayment(val) {
      if (val === 'SETTLEMENT') return 'Lunas'
      if (val === 'CANCELED') return 'Dibatalkan'
      if (val === 'DRAFT') return 'Belum Lunas'
      if (val === 'PENDING') return 'Belum Lunas'
      if (val === 'DECLINED') return 'Ditolak'
      if (val === 'PAYMENT_EXPIRED') return 'Kadaluarsa'
    },
    onShowModalExport() {
      if (this.sellerId) {
        this.showModalExportExcel = true
      } else {
        this.$notification.error({
          message: 'Terjadi kesalahan',
          description: 'harap pilih warehouse terlebih dahulu, sebelum export data invoice.',
        })
      }
    },
    onCloseModalExportExcel() {
      this.showModalExportExcel = false
    },
    async exportDataTable(params) {
      // get all data, filter date from frontend
      await getInvoiceList({
        business_id: this.businessId,
        params: {
          seller_id: this.sellerId,
          limit: this.pagination.total_items,
          statuses: params.statusPayment ?? undefined,
          page: 1,
        },
      })
      .then(({ data }) => {
        const filteredDataByDate = data.data.filter((obj) => this.$moment(obj.order_date).isBetween(params.startDate, params.endDate, 'days', '[]'))
        if (filteredDataByDate?.length > 0) {
          const flattenData = filteredDataByDate.map((obj) => ({
            invoice_number: obj.invoice_number,
            amount: format(obj.invoice_items[0].sub_total, 'IDR'),
            status: this.statusPayment(obj.payment.status),
            order_number: obj.order_number,
            full_name: obj.full_name ?? '-',
            order_date: this.$moment(obj.order_date).format('DD MMM YYYY, HH:mm'),
          }))
          const rowData = [
            "No. Invoice",
            "Total Tagihan",
            "Status Pembayaran",
            "No. Order",
            "Nama Customer",
            "Tanggal Pemesanan",
          ]
          flattenData.sort((a, b) => {
            return this.$moment(b.created_at) - this.$moment(a.created_at);
          })
          const worksheet = XLSX.utils.json_to_sheet(flattenData);
          const workbook = XLSX.utils.book_new()
          XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
          XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
          XLSX.writeFile(workbook,'list_invoice.xlsx')
        } else {
          this.$notification.error({
            message: 'Data Kosong',
            description: 'Tidak ada data berdasarkan filter tanggal tersebut.',
          })
        }
      })
      .catch((err) => {
        this.loading = false
        this.$notification.error({
          message: 'Terjadi kesalahan',
          description: 'Gagal saat memuat data.',
        })
        console.error(err)
      })
      .finally(() => {
        this.loading = false
        this.showModalExportExcel = false
      })
    },
    onSearch(e) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            invoice_number: e.target.value,
            page: 1,
          },
        })
      }, 1000)
    },
    onSearchFilter(value) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          statuses: value,
          page: 1,
        },
      })
    },
  },
}
</script>

<style lang="scss" module>
@import '../style.module.scss';
</style>
