import { reactive, toRefs, onMounted, watch, computed } from 'vue'
import store from '@/store'
import route from '@/router'
import debounce from 'lodash/debounce'
import { getSeller } from '@/api/channels/distributor'
import { getInvoiceList } from '@/api/invoice'
import { getWarehouseList } from '@/api/warehouse'

export default () => {
  const routeName = route.app._route.name
  const state_ = reactive({
    paymentFilterState: [
      {
      label: 'Semua',
      value: '',
      },
      {
      label: 'Lunas',
      value: 'SETTLEMENT',
      },
      {
      label: 'Belum Lunas',
      value: 'DRAFT,PENDING',
      },
      {
      label: 'Dibatalkan',
      value: 'CANCELED',
      },
      {
      label: 'Ditolak',
      value: 'DECLINED',
      },
      {
      label: 'Kadaluarsa',
      value: 'PAYMENT_EXPIRED',
      },
    ],
    dataPayment: [],
    modalSendInvoice: false,
    sellerId: '',
    pagination: {
      size: 20,
      total_items: 0,
    },
    businessId: '',
    warehouseData: [],
    selectedWarehouse: '',
    isLoading: false,
  })

  onMounted(
    () => {
      getWarehouse()
      getBusinessId()
      getInvoice()
  })

  watch(
    () => route.app._route.query, () => {
      if (route.app._route.name == routeName) {
        state_.isLoading = true
        getInvoice()
      }
    },
  )
  watch(
    () => state_.sellerId, () => {
      getInvoice()
    },
  )

  const page = computed(() => {
    return +route.app._route.query.page || 1
  })

  const handleSendInvoice = (param) => {
    state_.modalSendInvoice = param
  }

  const getBusinessId = async () => {
    return await store.state.user.businessList?.[0]?.business_id
  
  }

  const getInvoice = debounce(async () => {
    state_.isLoading = true
    let selectedSellerId
    const businessId = route.app._route.query.business_id || await getBusinessId()
    if (state_.sellerId) {
      selectedSellerId = state_.sellerId
    } else {
      if (route.app._route.query.warehouse_id) {
        await getSeller({
          businessId,
          params: { warehouse_id: route.app._route.query.warehouse_id },
        })
        .then(({ data: { data: response } }) => {
          selectedSellerId = response?.[0]?.seller_id || ''
          state_.sellerId = selectedSellerId
        })
        .catch(() => state_.sellerId = '')
      }
    }

    await getInvoiceList({
      business_id: businessId,
      params: {
        seller_id: selectedSellerId,
        limit: state_.pagination.size,
        page: page.value,
        statuses: route.app._route.query.statuses || undefined,
        invoice_number: route.app._route.query.invoice_number || undefined,
      },
    })
    .then(({ data }) => {
      state_.dataPayment = data?.data || []
      state_.pagination.total_items = data?.total_record || 0
      state_.isLoading = false
    })
    .catch((err) => {
      state_.isLoading = false
      state_.dataPayment = []
      console.error(err)
    })
  }, 500)

  const getWarehouse = async () => {
    if (store.state.user.restriction_base.toLowerCase() == 'business') {
      await getWarehouseList({ business_id: route.app._route.query.business_id })
      .then(async ({ data }) => {
        await getSeller({
          businessId: route.app._route.query.business_id,
          params: { warehouse_id: data?.data?.map((obj) => obj.id).join(',') },
        })
        .then(({ data: { data: response } }) => state_.warehouseData = response || [])
        .catch(() => state_.warehouseData = [])
      })
      .catch(() => state_.warehouseData = [])
    }
  }

  const handleSearch =  debounce((e) => {
    const q = e.target.value
    state_.searchParam = q
    route.push({
      query: {
        ...route.app._route.query,
        search: state_.searchParam || undefined,
        page: undefined,
      },
    })

  }, 500)

  const handleWarehouse = (e) => {
    state_.sellerId = e
  }

  const changePage = (value) => {
    route.push({
      query: { ...route.app._route.query, page: value, search: undefined, sort: undefined },
    })
  }

  const detailInvoice = (param) => {
    route.push({
      name: 'finance-channel.invoice-detail',
      query: {
        ...route.app._route.query,
        order_id: param,
      },
    })
  }

  return { ...toRefs(state_), handleSendInvoice, page, changePage, handleSearch, getInvoice, handleWarehouse, detailInvoice }
}